import {App, inject, InjectionKey} from 'vue';
import WlClient, {Options} from '@winelivery-org/wl-client-sdk-js';
import Cookies from 'js-cookie';
import router from '@/router';

const key: InjectionKey<WlClient> = Symbol();

class WlClientPlugin extends WlClient {
  install(app: App) {
    app.provide<WlClient>(key, this);
  }
}

export function createWlClient(options: Options): WlClientPlugin {
  return new WlClientPlugin(options);
}

export function useWlClient() {
  return inject(key)!;
}

const wlClient = createWlClient({
  debug: import.meta.env.DEV,
  isDev: import.meta.env.DEV,
  clientId: import.meta.env.VITE_WL_CLIENT_ID,
  appPlatform: 'backoffice',
  url: {
    api: import.meta.env.VITE_WL_API_URL,
    apis: import.meta.env.VITE_WL_APIS_URL,
  },
  onTokensChanged: ({refreshToken}) => {
    Cookies.set('__refresh_token__', refreshToken);
  },
  getRefreshToken: () => {
    return Cookies.get('__refresh_token__') || '';
  },
  shouldLogout: () => {
    Cookies.remove('__refresh_token__');
    router.replace({name: 'auth.sign_in'});
  },
});

export default wlClient;
